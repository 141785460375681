<template>
  <div class="app-container">
    <div class="content">
      <div class="title">
          <img src="@/assets/invoicing/title_add.png" style="width: 100%">
      </div>
      <el-form ref="mainForm" :rules="rules" :model="tmpData" label-width="8rem" class="form1">
            <el-form-item label='发票代码' prop="fpdm">
              <el-input v-model='tmpData.fpdm' maxlength="50" placeholder="请输入发票代码"/>
            </el-form-item>
            <el-form-item label='发票号码' prop='fphm'>
              <el-input v-model='tmpData.fphm' maxlength="50" placeholder="请输入发票号码"/>
            </el-form-item>
            <el-form-item label='开票日期' prop='kprq'>
              <el-date-picker
                v-model="tmpData.kprq"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="请选择开票日期">
              </el-date-picker>
            </el-form-item>

        <div class="button">
          <el-button type="primary" class="btn" @click="submitForm" :loading='isLoading' v-auth='"negativeElectronicInvoice:submit"'>确定</el-button>
          <!-- <el-button class="btn" @click="back" :disabled="cancelBtnDisabled">取消</el-button> -->
        </div>
      </el-form>
    </div>


  </div>
</template>

<script>
  export default {
    name: "negativeElectronicInvoice",
    components: {
    },
    data() {
      const checkPhone = (rule, value, callback) => {
        if(value) {
          var pass=false;
          const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
          const tel = /^0\d{2,3}-?\d{7,8}$/ // 座机
          if(mobile.test(value)){
            pass=true;
          }
          if(tel.test(value)){
            pass=true;
          }
          if (!pass) {
            return callback(
                new Error("请输入公司电话(座机格式'区号-座机号码')")
            )
          }
        }
        callback()
      }
      const checkTax = (rule, value, callback) => {
        if(value =='') {
          return callback(new Error("请输入纳税人识别号"))
        }else if(
          value.length == 15 ||
          value.length == 16 ||
          value.length == 17 ||
          value.length == 18 ||
          value.length == 19 ||
          value.length == 20){
          const pattern = /^[A-Z0-9]+$/
          if(!pattern.test(value)){
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"))
          }
          callback()
        }else{
          return callback(new Error("请检查纳税人识别号"))
        }
      }
      return {
        tmpData: {
          // fphm: '00625168',
          // fpdm: '042002100311',
          // kprq: '2022-12-21',

          fphm:"",
          fpdm:"",
          kprq:"",
        },
        rules: {
          fphm: [
            { required: true, message: "请输入发票号码" },
            // { min: 1, max: 20, message: "发票号码长度在 1 到 20 个字符", trigger: 'blur' },
            // {pattern: "^[^ ]+$",message:'发票号码不能含空格'}
          ],
          fpdm: [
            { required: true, message: "请输入发票代码" },
          ],
          kprq: [
            { required: true, message: "请选择开票日期" },
          ],
        },

        isLoading: false,  // 提交等待框
      };
    },
    methods: {
      submitForm() {
        this.$refs.mainForm.validate((valid) => {
          if (valid) {
            this.isLoading = true;
            this.$store.dispatch("QueryInvoiceDetail", { // 发票详情查询
              fpdm:this.tmpData.fpdm,
              fphm:this.tmpData.fphm,
              kprq:this.tmpData.kprq,
              companyId: this.getToken('companyId'),
            }).then(res => {
              if (res.success && res.data) {
                this.isLoading = false;
                this.$router.push({
                  path:'/invoicing/negativeElectronicInvoice/edit',
                  query:{
                    fpdm:this.tmpData.fpdm,
                    fphm:this.tmpData.fphm,
                    kprq:this.tmpData.kprq,
                    typeId: res.data.fplxdm == '026' ? '01' : '02', //“026”增值税电子普通发票；“028”增值税电子专用发票
                    companyId: this.getToken('companyId'),
                  }
                })
              } else {
                einvAlert.error("提示",res.msg)
                this.isLoading = false
              }
            }).catch(err => {
              this.$message.error(err)
              this.isLoading = false
            })
            

          }
        });
      },
   
    },
    mounted() {
      // if(!this.getToken('companyId')){
      //   this.$emit('doChangeMenuDisabled',true);
      // }
    }
  };
</script>

<style lang='less' scoped>
  .content {
    padding:  20px;
    height: calc(100% - 2rem);
    margin: 0 auto;
    background: #fff;
    .title {
      border-bottom: 1px solid #dcdcdc;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      position: relative;
      line-height: 70px;
      margin: 0;
      .btn-item {
        position: absolute !important;
        right: 2%;
        top: 32%;
      }
    }
  }
  .form1 {
    padding: 60px 25px 0;
    margin: 0 auto;
    width: 27rem;
    .date {
      display: flex;
      /deep/.el-input__inner {
        width: 100px;
      }
    }
  }
  .line {
    text-align: center;
  }
  .span-box {
    cursor: pointer;
    i {
      margin-right: 8px;
      color: #ff9700;
    }
  }
  .el-form-item__label {
    text-align: left;
  }
  .filter-item {
    width: 100%;
  }
  .years-item {
    width: 150px;
  }

  .button {
    margin: 40px auto 0;
    text-align: center;
    .btn {
      width: 150px;
      height: 40px;
      margin-right: 12px;
    }
  }
  /deep/ .el-autocomplete {
    width: 100%;
  }
  /deep/ .el-input__inner {
    width: 260px !important;
    height: 40px !important;
  }
</style>
